import { render, staticRenderFns } from "./Agreement.vue?vue&type=template&id=1d147636&scoped=true&"
import script from "./Agreement.vue?vue&type=script&lang=js&"
export * from "./Agreement.vue?vue&type=script&lang=js&"
import style0 from "@/assets/css/reset.css?vue&type=style&index=0&id=1d147636&scoped=true&lang=css&"
import style1 from "@/assets/css/store.css?vue&type=style&index=1&id=1d147636&scoped=true&lang=css&"
import style2 from "./Agreement.vue?vue&type=style&index=2&id=1d147636&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d147636",
  null
  
)

export default component.exports