<template>
  <div>
    <van-nav-bar class="nav_bar_view" left-arrow>
      <template #left>
        <img
          @click="returnPage"
          style="width: 18px; height: 18px"
          src="@/assets/black_return_view_bg.png"
          alt=""
        />
      </template>
      <template #title>
        <span style="color: black; font-size: 18px">用户协议</span>
      </template>
    </van-nav-bar>
    <div class="cvrp">
      <div class="cvrp_content">
        <h2>用户协议</h2>
        <p>应用名：菲鲜生</p>
        <p>
          菲鲜生的所有权与运作权归京山市群伟农产品经营部（以下简称“菲鲜生”）所有。
          本协议所称菲鲜生是指为菲鲜生会员提供水果蔬菜、海鲜肉禽、牛奶零食等全品类商品的网络交易服务平台，包括菲鲜生小程序等。
        </p>
        <h4>一、服务条款确认</h4>
        <p>
          在接受本协议之前,请您仔细阅读本协议的全部内容，尤其是加粗加下划线部分的内容。
          如果您不同意本协议的任意内容，或者无法准确理解菲鲜生对条款的解释，请不要进行后续操作。您在菲鲜生APP的交易行为，即表示您已经与菲鲜生达成本协议并同意接受本协议的全部约定内容以及与本协议有关的已经发布或将来可能发布的各项规则、页面提示、操作流程、公告和通知（以下统称本协议）。
        </p>
        <p>您在享用菲鲜生服务的同时，同意接受菲鲜生提供的各类信息服务。</p>
        <h4>二、账户注册与使用</h4>
        <h5>1、用户资格</h5>
        <p>
          您确认，在您开始使用菲鲜生服务前，您应当具备中华人民共和国法律规定的与您行为相适应的民事行为能力。若您不具备前述与您行为相适应的民事行为能力，则您及您的监护人应依照法律规定承担因此而导致的一切后果。
        </p>
        <h5>2、账户说明</h5>
        <p>
          当您通过微信平台“授权登录”后，菲鲜生将获取您的微信头像、微信昵称、微信号等，一个微信号仅能对应一个菲鲜生账户。您可以在您的账户中设置收件信息等个人资料。您使用菲鲜生时，您同意菲鲜生收集、存储、使用、披露和保护您的个人信息。
        </p>
        <h5>3、账户使用</h5>
        <p>
          由于您的菲鲜生账户关联您的个人信息及菲鲜生信息，您的菲鲜生账户仅限您本人使用。未经菲鲜生同意，任何第三方使用您账户的行为（包括但不限于在线签署各类协议、发布信息、购买商品及服务及披露信息等），都将认定为您的行为，并由您承担全部法律后果。
        </p>
        <h5>4、账户注销</h5>
        <p>
          您有权选择注销您的菲鲜生账户，但须保证所有菲鲜生订单已完结无纠纷。如您注销您的菲鲜生账户，您的个人信息（微信头像、微信昵称、微信号、收件信息等）将被清空，您的历史订单将无法查询，如您尚有未使用的权益（积分、红包、优惠券等）将视为您已放弃。
        </p>
        <h4>三、服务及规范</h4>
        <p>您有权在菲鲜生享受商品及/或服务的购买与评价、交易争议处理等服务。</p>
        <h5>1、商品及/或服务的购买</h5>
        <p>
          当您在菲鲜生购买商品及/或服务时，请您务必仔细确认所购商品的品名、价格、数量、型号、规格、尺寸或服务的时间、内容、限制性要求等重要事项，并在下单时核实您的联系地址、电话、收货人
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {

  },
  methods: {
    returnPage() {
      this.$router.replace("/login");
    },
  },
};
</script>
<style scoped src="@/assets/css/reset.css">
/* 局部引入css */
</style>
<style scoped src="@/assets/css/store.css">
/* 局部引入css */
</style>
<style lang="less" scoped>
@media screen and (min-width: 768px) {
  .nav_bar_view {
    max-width: 540px;
    margin-left: 50%;
    transform: translateX(-50%);
  }
  .address_list_view {
    max-width: 540px;
  }
}
</style>
